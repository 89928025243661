import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import {
  ChartApiService,
  ChartTurnoverResponse,
  GraphMode,
  GraphSize,
  TurnoverGraphDataType,
} from '@app/+authenticated/chart';
import { NotificationBannerMessageModel } from '@app/+authenticated/shared/notification-banner/notification-banner.component.model';
import { introductionModalConfig, IntroModalType } from '@app/+modals/introduction';
import { IntroductionModalComponent } from '@app/+modals/introduction/introduction-modal.component';
import { Features } from '@app/enums';
import { getAccount, getAccountSubscription } from '@app/reducers/account/account.service';
import { hasPermissionToCopySchedule } from '@app/reducers/orm/department/department.selectors';
import { getAuthenticatedUser } from '@app/reducers/orm/employee/employee.service';
import { isUserGuideFinished, UserGuideService, UserGuideStatus, UserGuideType } from '@app/reducers/orm/user-guide';
import { DashboardFilterType } from '@app/reducers/page-filters/page-filters-dashboard.config';
import { selectAllowedDashboardFilters } from '@app/reducers/page-filters/page-filters-dashboard.selectors';
import { OnboardingChecklistService } from '@app/shared/onboarding-checklist.service';
import { hasAtleastSubscriptionPlan } from '@app/shared/subscription-plan/subscription-plan.directive';
import { FeatureService } from '@app/startup/feature.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { subWeeks } from 'date-fns';
import { DialogService } from 'primeng/dynamicdialog';
import { combineLatest, Observable, of as observableOf, of, Subscription } from 'rxjs';
import { debounceTime, filter, switchMap, take } from 'rxjs/operators';

import { AppState } from '../../../reducers';
import {
  canPrintTimesheetStatement,
  getPermissionState,
  hasPermission,
} from '../../../reducers/auth/permission.helper';
import { DashboardFilterState } from '../../../reducers/page-filters/page-filters.model';
import { format } from '../../../shared/date.helper';
import { PlanType } from '../../+reports/shared/subscriptions/subscription.model';
import { SidebarComponent } from '../../shared/sidebar/sidebar.component';
import { bannerMessages, BannerType } from '../dashboard-feature-banner-messages';

@Component({
  selector: 'my-dashboard',
  templateUrl: './my-dashboard.component.html',
})
export class MyDashboardComponent implements OnInit {
  @ViewChild(SidebarComponent, { static: true })
  public sidebar: SidebarComponent;

  @HostBinding('class')
  private readonly classes = 'w-full';

  public filters = {
    from: format(subWeeks(new Date(), 2), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd'),
    group_by_period: 'day',
    group_by_location: 'department',
  };

  public isLoading = false;
  public graphData: ChartTurnoverResponse;

  public planType = PlanType;

  public authenticatedUser$ = this.store.select(getAuthenticatedUser);

  private dataSubs = new Subscription();
  public dashboardFilters: DashboardFilterState;
  public dashboardFilterType = DashboardFilterType;
  public hasNoWidget: boolean;

  public readonly turnOverGrapDataTypes = TurnoverGraphDataType;
  public readonly turnOverGrapSize = GraphSize.SMALL;
  public readonly turnOverGrapMode = GraphMode.WIDGET;

  public employeeGreetMessage: string;

  public showFeatureBanner: boolean;

  public dashboardFeatureMessage: NotificationBannerMessageModel;

  private bannerType?: BannerType;

  public constructor(
    private chartApi: ChartApiService,
    private store: Store<AppState>,
    protected userGuideService: UserGuideService,
    protected featureService: FeatureService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private onboardingChecklist: OnboardingChecklistService,
  ) {}

  public ngOnInit() {
    this.isLoading = true;

    this.dataSubs.add(
      this.store
        .select(getPermissionState)
        .pipe(
          switchMap((permissionState) => {
            const permission = hasPermission(
              {
                permissions: ['View reports'],
                userId: 'me',
                departments: 'any',
              },
              permissionState,
            );

            return permission ? this.chartApi.turnover(this.filters) : observableOf(undefined);
          }),
          take(1),
          filter((graphData) => !!graphData),
        )
        .subscribe((graphData) => {
          this.graphData = graphData;
          this.isLoading = false;
        }),
    );

    this.dataSubs.add(
      this.store.pipe(select(selectAllowedDashboardFilters)).subscribe((dashboardFilters) => {
        // TODO: fix no widget logic (this never gets set to true)
        this.hasNoWidget = Object.values(dashboardFilters).every((value) => !value);
        this.dashboardFilters = dashboardFilters;
      }),
    );
    this.setupFeatureBannner();
    this.employeeGreetMessage = this.generateGreetMessage();
  }

  private setupFeatureBannner() {
    this.bannerType = this.getBannerType();

    if (this.bannerType) {
      this.dataSubs.add(
        this.getFeatureMessage().subscribe((message) => {
          this.dashboardFeatureMessage = message;

          switch (this.bannerType) {
            case BannerType.QUICK_SCHEDULES:
              this.setupQuickSchedulesFeatureBanner();
              break;
            case BannerType.TIMESHEET_STATEMENTS:
              this.setupTimesheetStatementsFeatureBanner();
              break;

            case BannerType.PUBLIC_HOLIDAY_GROUP_PROMOTION:
              this.setupPublicHolidayGroupPromotionFeatureBanner();
              break;
          }
        }),
      );
    }
  }

  private getBannerType(): BannerType | null {
    const featureBannerMap: Partial<Record<Features, BannerType>> = {
      [Features.TMP_QUICK_SCHEDULES_INTRO_BANNER]: BannerType.QUICK_SCHEDULES,
      [Features.TMP_TIMESHEET_STATEMENTS_INTRO_BANNER]: BannerType.TIMESHEET_STATEMENTS,
      [Features.TMP_PUBLIC_HOLIDAY_GROUP_PROMOTION_INTRO_BANNER]: BannerType.PUBLIC_HOLIDAY_GROUP_PROMOTION,
    };

    // Features with banners sorted by priority
    const featurePriority: Features[] = [
      Features.TMP_PUBLIC_HOLIDAY_GROUP_PROMOTION_INTRO_BANNER,
      Features.TMP_QUICK_SCHEDULES_INTRO_BANNER,
      Features.TMP_TIMESHEET_STATEMENTS_INTRO_BANNER,
    ];

    // Get the first enabled feature
    const enabledFeature = featurePriority.find((feature) => this.featureService.isFeatureActivated(feature));

    return enabledFeature ? featureBannerMap[enabledFeature] : null;
  }

  private getFeatureMessage(): Observable<NotificationBannerMessageModel | null> {
    return this.store.pipe(select(getAccount)).pipe(
      switchMap((account) => {
        const mainMessage = bannerMessages[this.bannerType];

        if (!mainMessage.onlyApplicableTo || mainMessage.onlyApplicableTo.includes(account.country)) {
          const alternativeBanner = mainMessage.alternatives?.find((alternative) =>
            alternative.applicableTo.includes(account.country),
          );

          if (alternativeBanner) {
            return of(alternativeBanner.message);
          } else {
            return of(mainMessage);
          }
        }

        return of(null);
      }),
    );
  }

  private setupQuickSchedulesFeatureBanner() {
    this.dataSubs.add(
      this.userGuideService.isReady
        .pipe(
          filter((ready) => ready),
          switchMap(() =>
            combineLatest([
              this.store.pipe(select(isUserGuideFinished(UserGuideType.QUICK_SCHEDULES_MODAL))),
              this.store.pipe(select(isUserGuideFinished(UserGuideType.QUICK_SCHEDULES_BANNER))),
              this.store.pipe(select(isUserGuideFinished(UserGuideType.SIGNUP_GREETING_MODAL))),
              this.onboardingChecklist.isChecklistInProgress$.pipe(),
              this.store.pipe(select(getAccountSubscription)),
              this.store.pipe(select(getPermissionState)),
              this.store.pipe(select(hasPermissionToCopySchedule())),
            ]),
          ),
          filter(
            ([, , , , accountState, permissionState, hasPermission]) =>
              !!accountState && !!permissionState && !!hasPermission,
          ),
          debounceTime(300),
          take(1),
        )
        .subscribe({
          next: ([
            modalGuideFinished,
            bannerGuideFinished,
            signupGreetingFinished,
            checklistGuideInProgress,
            accountSubscription,
            ,
            hasPermission,
          ]) => {
            const viewRequirements =
              hasAtleastSubscriptionPlan(this.planType.PREMIUM, accountSubscription) && hasPermission;

            if (checklistGuideInProgress && !signupGreetingFinished) {
              return;
            }

            if (!viewRequirements) {
              return;
            }

            if (!modalGuideFinished) {
              this.showIntroductionModal({
                introModalType: IntroModalType.QUICK_SCHEDULES,
                userGuideType: UserGuideType.QUICK_SCHEDULES_MODAL,
              });
            }

            if (!bannerGuideFinished) {
              this.showFeatureBannerComponent();
            }
          },
        }),
    );
  }

  private setupPublicHolidayGroupPromotionFeatureBanner() {
    this.dataSubs.add(
      combineLatest([
        this.store.pipe(select(getAccountSubscription)),
        this.store.pipe(select(getPermissionState)),
      ]).subscribe({
        next: ([accountSubscription, permissionState]) => {
          const viewRequirements = hasAtleastSubscriptionPlan(this.planType.BASIC, accountSubscription);
          const viewPermissions = hasPermission(
            {
              permissions: ['Manage account'],
              userId: 'me',
              departments: 'any',
            },
            permissionState,
          );

          if (viewRequirements && viewPermissions) {
            this.showFeatureBannerComponent();
          }
        },
      }),
    );
  }

  private setupTimesheetStatementsFeatureBanner() {
    this.dataSubs.add(
      combineLatest([
        this.store.pipe(select(getAccountSubscription)),
        this.store.pipe(select(getPermissionState)),
      ]).subscribe({
        next: ([accountSubscription, permissionState]) => {
          const viewRequirements = hasAtleastSubscriptionPlan(this.planType.PREMIUM, accountSubscription);
          const canPrint = canPrintTimesheetStatement(permissionState);

          if (viewRequirements && canPrint) {
            this.showFeatureBannerComponent();
          }
        },
      }),
    );
  }

  public showIntroductionModal(data): void {
    this.dialogService.open(IntroductionModalComponent, {
      data,
      ...introductionModalConfig,
    });
  }

  public showFeatureBannerComponent(): void {
    if (this.dashboardFeatureMessage) {
      this.showFeatureBanner = true;
    }
  }

  public closeFeatureBanner(): void {
    this.showFeatureBanner = false;

    if (this.bannerType === BannerType.ABSENCE_IN_DAYS) {
      this.dataSubs.add(
        this.userGuideService
          .trackUserGuide({
            guide: UserGuideType.ABSENCE_IN_DAYS_BANNER,
            status: UserGuideStatus.FINISHED,
          })
          .subscribe(),
      );
    }
  }

  private generateGreetMessage(): string {
    const today = new Date();
    const hours = today.getHours();

    if (hours >= 0 && hours < 7) {
      return this.translate.instant('Good night');
    }
    if (hours >= 7 && hours < 12) {
      return this.translate.instant('Good morning');
    }
    if (hours >= 12 && hours < 18) {
      return this.translate.instant('Good afternoon');
    }
    if (hours >= 18) {
      return this.translate.instant('Good evening');
    }
    return '';
  }

  public toggleSideBar() {
    this.sidebar.toggle();
  }
}
